<template>
  <v-dialog
    v-model="isModalOpen"
    width="700"
    persistent
  >
    <v-form
      ref="form"
      v-model="is_valid"
    >
      <v-card v-if="currentTransferItem">
        <v-toolbar
          class="py-2"
          flat
        >
          <v-toolbar-title>
            <h2 class="font-weight-regular text-h3">{{ $t("add_item") }}</h2>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            class="mr-0"
          >
            <v-icon @click="close()">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="px-8">
          <div class="d-flex flex-row">
            <v-img
              class="me-4"
              max-height="200"
              max-width="200"
              v-if="currentTransferItem?.image"
              :src="currentTransferItem?.image?.url"
            />
            <v-img
              v-else
              class="me-4"
              src="@/assets/product.png"
              max-height="100"
              max-width="100"
            />
            <div class="">
              <label class="ma-1 font-weight-regular">{{
                currentTransferItem?.name
              }}</label>
              <br />
              <label class="text--disabled ma-1 body-1">
                ID: {{ currentTransferItem.code }} . Barcode :
                {{ currentTransferItem.barcode }}</label
              >
              <br />
              <p
                v-if="currentTransferItem?.moving_quantity < 0"
                class="mt-2 mb-0 ml-1 font-weight-medium red--text text--lighten-1"
              >
                {{ currentTransferItem?.moving_quantity }} moving
              </p>
              <p
                v-if="currentTransferItem?.moving_quantity > 0"
                class="mt-2 mb-0 ml-1 font-weight-medium green--text text--lighten-1"
              >
                + {{ currentTransferItem?.moving_quantity }} moving
              </p>
              <p class="my-1 ml-1 font-weight-medium">
                {{ currentTransferItem?.qte_in_hub ?? 0 }} items in stock
              </p>
              <p class="mt-0 ml-1 font-weight-medium">
                {{
                  currentTransferItem?.qte_in_hub_dest ??
                  getStockInDestination(currentTransferItem)
                }}
                items in destination Hub
              </p>
            </div>
          </div>

          <p class="d-flex align-center mt-4 mb-0">
            <span class="me-6">{{ $t("transfer_by_batch") }}</span>
            <v-switch v-model="currentTransferItem.by_lots"></v-switch>
          </p>

          <div v-if="currentTransferItem.by_lots">
            <v-row>
              <v-col cols="6"
                ><v-text-field
                  v-model="search"
                  :label="$t('search')"
                  class=""
                  outlined
                  dense
                  hide-details="auto"
                ></v-text-field
              ></v-col>
              <v-col cols="12">
                <v-data-table
                  :headers="lotsHeader"
                  :items="lots"
                  dense
                  :search="search"
                  hide-default-footer
                >
                  <template v-slot:body.append>
                    <tr>
                      <td></td>
                      <td
                        colspan="2"
                        class="font-weight-bold"
                      >
                        {{ $t("total_batch_qte") }}
                      </td>
                      <td>
                        <v-text-field
                          type="number"
                          class="text-right font-weight-bold"
                          v-model="totalBatchQte"
                          readonly
                          reverse
                          flat
                          :rules="[rules.min_quantity(1)]"
                          >{{ totalBatchQte }}</v-text-field
                        >
                      </td>
                      <td colspan="4"></td>
                    </tr>
                  </template>
                  <template v-slot:item.qte="{ item }">
                    <v-text-field
                      class="my-1"
                      hide-details="auto"
                      outlined
                      dense
                      type="number"
                      v-model="item.qte"
                      @keypress="isNumber($event)"
                      :rules="[
                        rules.min_quantity(0),
                        rules.max_quantity(item.qte_in_lot),
                      ]"
                    ></v-text-field>
                  </template>

                  <template v-slot:no-data>
                    {{ $t("empty") }}
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </div>
          <div v-else>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  dense
                  hide-details="auto"
                  outlined
                  :label="$t('quantity') + '(' + $t('items') + ')'"
                  v-model="currentTransferItem.qte_in_transfer"
                  @keypress="isNumber($event)"
                  :rules="[
                    rules.min_quantity(1),
                    rules.max_quantity(currentTransferItem.qte_in_hub),
                  ]"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="mb-6"
            color="#6100ea"
            @click="saveForm()"
            :loading="isLoading"
            :disabled="isLoading || !is_valid"
          >
            <span> {{ $t("add_items_to_cart") }} </span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import { validationRules } from "@/mixins/validationRules";
import service from "@/store/services/transfer-product-service";
import { Product } from "../../setup/products/product.class";
import lotService from "@/store/services/lot-service";
export default {
  mixins: [validationRules],
  props: {
    isModalOpen: Boolean,
    transferItem: Object,
    toggleModal: Function,
  },
  mounted: function () {
    this.currentTransferItem = { ...this.transferItem };
    this.prepareProduct();
    this.getBatches();
  },
  computed: {
    isVisibleDialog: {
      get() {
        return this.$store.state.products.isVisibleDialog;
      },
      set(value) {
        this.$store.commit("products/IS_VISIBLE_DIALOG", value);
      },
    },
    transfer: {
      get() {
        return this.$store.state.transferProducts.transfer;
      },
      set(value) {
        this.$store.commit("transferProducts/TRANSFER", value);
      },
    },
  },

  watch: {
    lots: {
      handler(newValue) {
        this.totalBatchQte = newValue
          .map((item) => {
            return parseFloat(item.qte);
          })
          .reduce((a, b) => a + b, 0);
        // this.currentTransferItem.qte_in_transfer = this.totalBatchQte;
      },
      deep: true,
    },
  },

  data() {
    return {
      product: new Product(),
      editUnit: null,
      totalBatchQte: 0,
      lots: [],
      search: null,
      message: null,
      currentTransferItem: null,
      qte_by_type_packaging: 0,
      is_valid: false,
      discount: 0,
      isLoading: false,
      lotsHeader: [
        {
          text: this.$t("lot_number"),
          align: "start",
          sortable: true,
          value: "lot_number",
        },
        {
          text: this.$t("expiration_date"),
          align: "start",
          sortable: true,
          value: "expiration_date",
        },
        {
          text: this.$t("qte"),
          align: "start",
          sortable: true,
          value: "qte_in_lot",
        },
        {
          text: this.$t("qte_to_transfer"),
          align: "start",
          sortable: false,
          value: "qte",
        },
      ],
    };
  },

  methods: {
    close() {
      this.toggleModal();
    },

    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        // charCode > 31 &&
        charCode < 48 ||
        charCode > 57
        // charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    prepareProduct() {
      const orderItem = {
        qte_in_transfer: this.currentTransferItem.qte_in_transfer,
        code: this.currentTransferItem.code,
        id: this.currentTransferItem.id,
        transfer_id: this.transfer.id,
      };
      Object.assign(this.currentTransferItem, orderItem);
    },

    async getBatches() {
      const product_id = this.currentTransferItem.id;
      const hub_id = this.transfer.from_hub_id;
      this.lots = (
        await lotService.list({ product_id, hub_id, status: "active" })
      ).lots;
      this.lots = this.lots.map(
        function (item) {
          const qte =
            this.currentTransferItem.lots?.find((lot) => item.id == lot.id)
              ?.qte ?? 0;
          return {
            ...item,
            qte,
          };
        }.bind(this)
      );
    },

    validate() {
      this.$refs.form.validate();
    },
    async saveForm() {
      this.isLoading = true;
      this.validate();
      if (this.is_valid) {
        try {
          if (!this?.transfer?.id) {
            const createdTransfer = await service.add({
              ...this.transfer,
            });
            this.transfer.id = createdTransfer.id;
          }
          this.currentTransferItem.transfer_id = this.transfer.id;

          if (this.currentTransferItem?.by_lots) {
            this.currentTransferItem.qte_in_transfer = this.totalBatchQte;
          } else {
            this.lots = [];
          }

          // prepare data for add to cart
          const transferItem = {
            id: this.currentTransferItem.id,
            transfer_id: this.transfer.id,

            by_lots: this.currentTransferItem.by_lots ?? false,
            qte_in_transfer: this.currentTransferItem.qte_in_transfer ?? null,
            lots: this.lots.filter((item) => item.qte > 0),
          };

          await this.$store.dispatch(
            "transferProducts/addToCart",
            transferItem
          );

          this.toggleModal();
          this.$store.dispatch(
            "alerts/success",
            this.$t("item_added_successfully")
          );
        } catch (error) {
          this.isLoading = false;
          this.$store.dispatch("alerts/error", error.response?.data?.message);
        }
      }
    },

    getStockInDestination(item) {
      if (this.transfer.to_hub_id && item.hubs) {
        const distHub = item.hubs.find(
          (hub) => hub.id == this.transfer.to_hub_id
        );
        if (distHub) return distHub.qte_in_hub;
      }
      return "-";
    },
  },
};
</script>